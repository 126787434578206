import axios from 'axios';

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        if (!error.message) {
            error.message = 'Network Error';
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
