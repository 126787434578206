import React, { useState } from 'react';
import { FaCopy, FaCheck, FaChevronDown, FaChevronUp } from 'react-icons/fa';

interface TranscriptionOutputProps {
    transcription: string;
    clearTranscription: () => void;
    index: number;
    timeBlock?: string;
}

const TranscriptionOutput: React.FC<TranscriptionOutputProps> = ({ transcription, clearTranscription, index, timeBlock }) => {
    const [copied, setCopied] = useState(false);
    const [isOpen, setIsOpen] = useState(true);

    const handleCopy = () => {
        if (navigator.clipboard && navigator.clipboard?.writeText) {
            navigator.clipboard.writeText(transcription);
            setCopied(true);
            setTimeout(() => setCopied(false), 1500);
        } else {
            // Fallback method for older browsers or unsupported environments
            const textarea = document.createElement('textarea');
            textarea.value = transcription;
            textarea.style.position = 'fixed'; // Prevent scrolling to the bottom of the page
            document.body.appendChild(textarea);
            textarea.focus();
            textarea.select();

            try {
                document.execCommand('copy');
                setCopied(true);
                setTimeout(() => setCopied(false), 1500);
            } catch (err) {
                console.error('Fallback: Unable to copy text', err);
            }

            document.body.removeChild(textarea);
        }
    };

    const togglePanel = () => {
        setIsOpen(!isOpen);
    };

    const label = timeBlock ? timeBlock : `Output ${index+1}`

    return (
        <div className="mt-4 bg-gray-800 rounded">
            <div className="relative flex items-center justify-between text-gray-400 bg-gray-900 p-2 rounded-t">
                <h2 className="text-2xl text-white flex items-center">
                    <button
                        className="bg-transparent text-gray-400 hover:text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                        onClick={togglePanel}
                    >
                        {isOpen ? <FaChevronUp className="inline" /> : <FaChevronDown className="inline" />}
                    </button>
                    Transcribed - {label}
                </h2>
                <button
                    className="bg-transparent text-gray-400 hover:text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                    onClick={handleCopy}
                >
                    {copied ? <FaCheck className="inline" /> : <FaCopy className="inline" />} {copied ? 'Copied' : 'Copy output'}
                </button>
            </div>
            {isOpen && (
                <div className="p-4">
                    <div className="text-white whitespace-pre-wrap" style={{ lineHeight: "2em" }}>{transcription}</div>
                    <button
                        className="mt-2 ml-2 text-gray-400 font-bold py-2 px-4 rounded"
                        onClick={clearTranscription}
                    >
                        Clear Transcription
                    </button>
                </div>
            )}
        </div>
    );
};

export default TranscriptionOutput;
