import React, { useState, useEffect } from 'react';
import quotes from '../api/quotes.json';
import './styles/QuoteDisplay.css';

const QuoteDisplay: React.FC = () => {
    const [currentQuoteIndex, setCurrentQuoteIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentQuoteIndex(Math.floor(Math.random() * quotes.length));
        }, 10000);

        return () => clearInterval(interval);
    }, []);

    const { quote, author } = quotes[currentQuoteIndex];

    return (
        <div className="quote-container">
            <p className="quote-text">"{quote}"</p>
            <p className="quote-author">- {author}</p>
        </div>
    );
};

export default QuoteDisplay;
