import React, { useState, useEffect } from 'react';

interface BannerProps {
    message: string;
}

const Banner: React.FC<BannerProps> = ({ message }) => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const bannerData = JSON.parse(localStorage.getItem('bannerData') || '{}');
        const currentTime = new Date().getTime();
        const oneDay = 24 * 60 * 60 * 1000;

        if (!bannerData.lastShown) {
            bannerData.count = 0;
        }

        const isPastOneDay = currentTime - bannerData.lastShown >= oneDay

        if (!bannerData.count || (bannerData.count < 3 && isPastOneDay)) {
            setVisible(true);
        }
    }, []);

    const handleClose = () => {
        setVisible(false);
        const bannerData = JSON.parse(localStorage.getItem('bannerData') || '{}');
        const currentTime = new Date().getTime();

        bannerData.count = (bannerData.count || 0) + 1;
        bannerData.lastShown = currentTime;

        localStorage.setItem('bannerData', JSON.stringify(bannerData));
    };

    useEffect(() => {
        if (visible) {
            const timer = setTimeout(() => {
                handleClose();
            }, 2 * 60 * 1000); // Auto-hide after 2 minutes

            return () => clearTimeout(timer);
        }
    }, [visible]);

    if (!visible) return null;

    return (
        <div className="fixed top-0 left-0 right-0 bg-yellow-400 text-black px-4 py-3 shadow-md flex justify-between items-center">
            <span>{message}</span>
            <button onClick={handleClose} className="text-black font-bold">X</button>
        </div>
    );
};

export default Banner;
