import React from 'react';
import logo from '../images/dc-logo.png';

const SplashScreen: React.FC = () => {
  return (
    <div className="flex justify-center items-center h-screen bg-blue-600">
      <div className="text-center">
        <img src={logo} alt="DC Transcriber Logo" className="animate-bounce mb-4" style={{ height: '100px' }} />
        <h1 className="text-white text-2xl">Loading...</h1>
      </div>
    </div>
  );
};

export default SplashScreen;