import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import TranscriptionPage from './TranscriptionPage';
import SplashScreen from 'components/SplashScreen';
import { useAuth0 } from '@auth0/auth0-react';

const App: React.FC = () => {
  const { isAuthenticated, loginWithRedirect, isLoading } = useAuth0();

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect();
    }
  }, [isAuthenticated, loginWithRedirect, isLoading]);

  if (isLoading) {
    return <SplashScreen />;
  }

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={isAuthenticated ? <Navigate to="/transcription" /> : <SplashScreen />}
        />
        <Route
          path="/transcription"
          element={isAuthenticated ? <TranscriptionPage /> : <Navigate to="/" />}
        />
      </Routes>
    </Router>
  );
};

export default App;
