import React from 'react';
import '../styles/ProgressBar.css'

interface ProgressBarProps {
    progress: number;
    label: string;
    indeterminate: boolean;
}

const ProgressBar: React.FC<ProgressBarProps> = React.memo(({ progress, label, indeterminate }) => {
    const progressBarStyle = indeterminate
        ? 'w-full animate-gradient-x'
        : `transition-all duration-300`;

    const progressBarColor = indeterminate ? 'bg-blue-500' : 'bg-green-500';

    return (
        <div className="relative pt-1">
            <div className="overflow-hidden h-6 mb-4 text-xs flex rounded bg-blue-200">
                <div
                    style={{ width: indeterminate ? '100%' : `${progress}%` }}
                    className={`shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center ${progressBarStyle} ${progressBarColor}`}
                >
                    <span className="text-sm">{label}</span>
                </div>
            </div>
        </div>
    );
});

export default React.memo(ProgressBar);
