import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import '../auth/Profile.css';
import { FaSignOutAlt } from 'react-icons/fa';

const Profile = () => {
  const { user, isAuthenticated, logout } = useAuth0();

  return (
    isAuthenticated && (
      <div className="profile-container">
        {user?.picture && <img src={user.picture} alt={user?.name} className="profile-picture" />}
        <div className="profile-details">
          <h2 className="profile-name">{user?.name}</h2>
          <p className="profile-email">{user?.email}</p>
          <button
                onClick={() =>
                    logout({
                    logoutParams: { returnTo: window.location.origin }
                    })
                }
                className="logout-button"
                >
                <FaSignOutAlt /> Log Out
            </button>
        </div>
      </div>
    )
  );
};

export default Profile;
