import React, { useRef, useEffect } from 'react';
import '../styles/AudioPlayer.css';

interface AudioPlayerProps {
    audioFile: File | null;
    startTime: string;
    endTime: string;
}

const AudioPlayer: React.FC<AudioPlayerProps> = ({ audioFile, startTime, endTime }) => {
    const audioRef = useRef<HTMLAudioElement>(null);

    useEffect(() => {
        const audioElement = audioRef.current;
        if (!audioElement) return;

        const startSeconds = timeToSeconds(startTime);
        const endSeconds = timeToSeconds(endTime);

        audioElement.currentTime = startSeconds;

        const handleTimeUpdate = () => {
            if (audioElement.currentTime >= endSeconds) {
                audioElement.pause();
                audioElement.currentTime = startSeconds;
            }
        };

        audioElement.addEventListener('timeupdate', handleTimeUpdate);
        audioElement.addEventListener('ended', () => (audioElement.currentTime = startSeconds));

        return () => {
            audioElement.removeEventListener('timeupdate', handleTimeUpdate);
            audioElement.removeEventListener('ended', () => (audioElement.currentTime = startSeconds));
        };
    }, [startTime, endTime]);

    const timeToSeconds = (time: string): number => {
        const [hours, minutes, seconds] = time.split(':').map((v) => parseInt(v, 10));
        return hours * 3600 + minutes * 60 + seconds;
    };

    return (
        <div className="audio-player">
            <audio ref={audioRef} src={audioFile ? URL.createObjectURL(audioFile) : ''} controls />
        </div>
    );
};

export default AudioPlayer;
